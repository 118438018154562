
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';

export default defineComponent({
  name: 'Article',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();

    const tableOpts = {
      search: {
        fetch: true,
        criteria: {
          cols: []
        },
        remote: {
          action: https?.facade.pageArticle
        }
      },
      cols: [
        {
          field: 'title',
          label: '标题'
        },
        {
          field: 'content',
          label: '内容'
        },
        {
          field: 'sort',
          label: '排序',
          width: 100
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr,
          width: 100
        },
        {
          field: 'imgUrl',
          label: '背景图片',
          ability: 'preview',
          width: 100
        },
        {
          field: 'remark',
          label: '备注',
          width: 140
        },
        {
          field: 'createTime',
          label: '创建时间',
          width: 160
        },
        {
          field: 'updateTime',
          label: '修改时间',
          width: 160
        }
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'title',
              label: '标题',
              required: true
            },
            {
              field: 'content',
              label: '内容',
              type: 'textarea',
              required: true
            },
            {
              field: 'sort',
              label: '排序',
              type: 'number',
              required: true
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableStr,
              required: true
            },
            {
              field: 'imgUrl',
              label: '背景图片',
              type: 'upload-pic-card',
              required: true
            },
            {
              field: 'remark',
              label: '备注'
            }
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.facade.createArticle },
        update: { action: https?.facade.updateArticle },
        delete: { action: https?.facade.deleteArticle }
      }
    };
    return { tableOpts };
  }
});
